import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lineItemFilter from "./TaxLineItemFilter";

export default invoice => ({
  contact: {
    id: invoice.contact.id
  },
  project: {
    id: invoice.project.id
  },
  currency: {
    id: invoice.currency.id
  },
  number: invoice.number,
  reference: invoice.reference,
  issuedDate: invoice.issuedDate,
  dueDate: invoice.dueDate,
  exchangeRate: invoice.exchangeRate,
  lineItems: reject(
    li => isEmpty(li.description) || isNil(li.description),
    invoice.lineItems.map(li => lineItemFilter(li))
  )
});
