<template lang="pug">
  .invoice
    project-facade(v-if="$route.params.id" active-tab="invoice", :project-id="$route.params.id")
    invoice-form(
      v-model='invoice',
      submit-label='Add invoice',
      :saving='saving',
      :loading='$apollo.loading',
      @save='createInvoice',
      @cancel='cancelCreation')
</template>

<script>
import createInvoiceMutation from "@/graphql/mutations/finance/invoices/CreateInvoice.gql";
import createInvoiceFilter from "@/graphql/mutations/filters/CreateInvoiceFilter";
import { formatDateApi } from "@/helpers/DateHelpers";
import ProjectQuery from "@/graphql/queries/finance/Project.gql";
import mutate from "@/components/utils/Mutator";
import InvoiceForm from "./Form.vue";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    project: {
      query: ProjectQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      skip() {
        return !this.$route.params.id;
      }
    }
  },
  components: {
    InvoiceForm,
    ProjectFacade
  },
  data() {
    return {
      saving: false,
      project: null,
      invoice: {
        issuedDate: formatDateApi(),
        currency: this.$store.state.defaultCurrency,
        lineItems: [{ key: 0 }, { key: 1 }, { key: 2 }]
      }
    };
  },
  watch: {
    project(project) {
      this.$set(this.invoice, "project", project);
      this.$set(this.invoice, "currency", project.currency);
    }
  },
  methods: {
    createInvoice() {
      const vm = this;
      this.saving = true;

      return mutate(this, {
        mutation: createInvoiceMutation,
        variables: {
          input: {
            ...createInvoiceFilter(this.invoice)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/projects/${this.invoice.project.id}/invoices/${data.data.createInvoice.invoice.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelCreation() {
      if (this.$route.params.id) {
        window.location.assign(`/projects/${this.$route.params.id}/#invoices`);
      } else {
        window.location.assign("/finances/#invoices");
      }
    }
  }
};
</script>
